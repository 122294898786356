import Home from './Home'
import Products from './Products'
import Services from './Services'
import Contact from './Contact'
import Header from './Header'
import './App.css';
import { Route, Routes } from 'react-router-dom'

function App() {
  return (
    <>
      <Header />
      <Routes>
        <Route path="/" element={<Home />}></Route>
        <Route path="/products" element={<Products />}></Route>
        <Route path="/services" element={<Services />}></Route>
        <Route path="/contact" element={<Contact />}></Route>
        <Route path="/getaquote" element={<Contact />}></Route>

      </Routes>



      <footer className="bg-gray-200">

        <div className="block md:hidden bg-white pb-6">

          <p className="mt-2 text-center lg:text-left text-gray-800 md:mt-4 sm:text-xl lg:text-lg italic">
            34 years in business
          </p>
          {/* <p className="mt-8 text-base font-semibold text-white sm:mt-10">Used by</p> */}
          <div className="mt-6 flex">
            <div className="mx-auto">
              <a id="bbblink" className="ruhzbum" href="http://www.bbb.org/tulsa/business-reviews/auto-upholstery/southeast-auto-trim-in-tulsa-ok-29000913#bbbseal" title="Southeast Auto Trim, Inc., Auto Upholstery, Tulsa, OK">
                <img id="bbblinkimg" src="http://seal-tulsa.bbb.org/logo/ruhzbum/southeast-auto-trim-29000913.png" alt="Southeast Auto Trim, Inc., Auto Upholstery, Tulsa, OK" style={{ width: 250 }} />
              </a>
            </div>
          </div>
        </div>

        <div className="mx-auto max-w-7xl overflow-hidden px-6 py-20 sm:py-24 lg:px-8">
          <nav aria-label="Footer" className="-mb-6 columns-2 sm:flex sm:justify-center sm:space-x-12">
            <div key={1} className="pb-6">
              <a href={"/"} className="text-sm leading-6 text-gray-600 hover:text-gray-900">
                Home
              </a>
            </div>
            <div key={1} className="pb-6">
              <a href={"/products"} className="text-sm leading-6 text-gray-600 hover:text-gray-900">
                Products
              </a>
            </div>
            <div key={1} className="pb-6">
              <a href={"/services"} className="text-sm leading-6 text-gray-600 hover:text-gray-900">
                Services
              </a>
            </div>
            <div key={1} className="pb-6">
              <a href={"/contact"} className="text-sm leading-6 text-gray-600 hover:text-gray-900">
                Contact
              </a>
            </div>
          </nav>
          {/* <div className="mt-10 flex justify-center space-x-10">
          {navigation.social.map((item) => (
            <a key={item.name} href={item.href} className="text-gray-400 hover:text-gray-500">
              <span className="sr-only">{item.name}</span>
              <item.icon aria-hidden="true" className="h-6 w-6" />
            </a>
          ))}
        </div> */}
          <p className="mt-10 text-center text-xs leading-5 text-gray-500">
            &copy; 2024 South East Auto Trim. All rights reserved.
          </p>
        </div>
      </footer>
    </>
  )
}


export default App;
