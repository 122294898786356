import { useEffect } from 'react'
import { Popover, PopoverButton, PopoverPanel } from '@headlessui/react'
import { Bars3Icon, XMarkIcon } from '@heroicons/react/24/outline'
import './App.css';
import logo from './seat-logo.png';
import './App.css';
import { Link, useNavigate } from 'react-router-dom'

const navigation = [
    { name: 'Products', href: 'products' },
    { name: 'Services', href: '/services' },
    { name: 'Contact Us', href: '/contact' },
]



function Header() {

    const navigate = useNavigate();

    const getQuote = () => {
        navigate("/contact");
    }


    return (


        <div className="relative overflow-hidden header">
            <div className="bg-gradient-to-r from-cyan-500 to-blue-500"></div>
            <div aria-hidden="true" className="hidden sm:absolute sm:inset-0 sm:block">

                <svg
                    fill="none"
                    width={364}
                    height={384}
                    viewBox="0 0 364 384"
                    className="absolute bottom-0 right-0 mb-48 translate-x-1/2 transform text-gray-700 lg:top-0 lg:mb-0 lg:mt-28 xl:translate-x-0 xl:transform-none"
                >
                    <defs>
                        <pattern
                            x={0}
                            y={0}
                            id="eab71dd9-9d7a-47bd-8044-256344ee00d0"
                            width={20}
                            height={20}
                            patternUnits="userSpaceOnUse"
                        >
                            <rect x={0} y={0} fill="currentColor" width={4} height={4} />
                        </pattern>
                    </defs>
                    <rect fill="url(#eab71dd9-9d7a-47bd-8044-256344ee00d0)" width={364} height={384} />
                </svg>
            </div>


            <div className="relative pb-6 pt-6 sm:pb-6">
                <Popover>
                    <nav aria-label="Global" className="relative mx-auto flex max-w-7xl items-center justify-between px-6">
                        <div className="flex flex-1 items-center">
                            <div className="flex w-full items-start justify-between md:w-auto">
                                <a href="#">
                                    <span className="sr-only">South East Auto Trim</span>
                                    <Link to="/"><img className="w-32" src={logo} /></Link>
                                </a>


                                <div className="tagline-3 text-center text-white sm:relative md:hidden" >

                                    <h2 className="block md:hidden mt-4 text-lg text-white">
                                        A full-service <br />automotive trim shop
                                    </h2>

                                    <br />
                                    <span><b> Mon - Fri: 8am - 5pm</b></span>
                                    <br />
                                    <span>(918) 664-7328</span>
                                    <br />
                                    <span>(877) 530-7328</span>


                                </div>

                                <div className="-mr-2 flex items-center md:hidden">
                                    <PopoverButton className="focus-ring-inset relative inline-flex items-center justify-center rounded-md bg-red-500 p-2 text-white focus:outline-none focus:ring-2 focus:ring-white">
                                        <span className="absolute -inset-0.5" />
                                        <span className="sr-only">Open main menu</span>
                                        <Bars3Icon aria-hidden="true" className="h-6 w-6" />
                                    </PopoverButton>
                                </div>
                            </div>

                            <div className="hidden space-x-10 md:ml-10 lg:flex">
                                {navigation.map((item) => (
                                    <a key={item.name} href={item.href} className="uppercase text-xl text-white hover:text-gray-300">
                                        {item.name}
                                    </a>
                                ))}
                            </div>


                        </div>


                        <div className="hidden md:flex md:flex-1 md:justify-center">
                            {/* <a
                href="#"
                className="inline-flex items-center rounded-md border border-transparent bg-gray-600 px-4 py-2 text-sm font-medium text-white hover:bg-gray-700"
              >
                Log in
              </a> */}
                            <div className="tagline-3 text-center text-white" >
                                <span><b>Hours: Mon - Fri 8am - 5pm</b></span>
                                <br />
                                <span>(918) 664-7328</span>
                                <br />
                                <span>(877) 530-7328</span>
                            </div>

                        </div>
                    </nav>

                    <PopoverPanel
                        focus
                        transition
                        className="absolute inset-x-0 top-0 z-10 origin-top-right transform p-2 transition data-[closed]:scale-95 data-[closed]:opacity-0 data-[enter]:duration-150 data-[leave]:duration-100 data-[enter]:ease-out data-[leave]:ease-in md:hidden"
                    >
                        <div className="overflow-hidden rounded-lg bg-red-500 shadow-md ring-1 ring-black ring-opacity-5">
                            <div className="flex items-center justify-between px-5 pt-4">
                                <div>
                                    {/* <img
                                        alt=""
                                        src="https://tailwindui.com/img/logos/mark.svg?color=indigo&shade=600"
                                        className="h-8 w-auto"
                                    /> */}
                                    <div className="logo-letters">
                                        <div className="logo-letter">
                                            <span>S</span>
                                        </div>
                                        <div className="logo-letter">
                                            <span>E</span>
                                        </div>
                                        <div className="logo-letter">
                                            <span>A</span>
                                        </div>
                                        <div className="logo-letter">
                                            <span>T</span>
                                        </div>
                                    </div>
                                </div>
                                <div className="-mr-2">
                                    <PopoverButton className="relative inline-flex items-center justify-center rounded-md bg-white p-2 text-gray-400 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500">
                                        <span className="absolute -inset-0.5" />
                                        <span className="sr-only">Close menu</span>
                                        <XMarkIcon aria-hidden="true" className="h-6 w-6" />
                                    </PopoverButton>
                                </div>
                            </div>
                            <div className="space-y-1 px-2 pb-3 pt-2">
                                {navigation.map((item) => (
                                    <a
                                        key={item.name}
                                        href={item.href}
                                        className="block rounded-md px-3 py-2 text-2xl bg-red-600 text-white hover:bg-gray-50 hover:text-gray-900"
                                    >
                                        {item.name}
                                    </a>
                                ))}
                            </div>
                            {/* <a
                                href="#"
                                className="block w-full bg-gray-50 px-5 py-3 text-center font-medium text-indigo-600 hover:bg-gray-100"
                            >
                                Log in
                            </a> */}
                        </div>
                    </PopoverPanel>
                </Popover>

                <main className="mt-6 sm:mt-6">
                    <div className="mx-auto max-w-7xl">
                    {window.location.pathname != "/contact" && window.location.pathname != "/getaquote" && (
                        <div className="lg:grid lg:grid-cols-12 lg:gap-8">
                            <div className="px-6 text-center md:mx-auto md:max-w-2xl lg:col-span-6 lg:flex lg:items-center lg:text-left">

                            
                                    <div className="hidden md:block" >

                                        <h1 className="text-4xl font-bold tracking-tight text-white sm:text-5xl md:text-5xl">
                                            South East Auto Trim
                                        </h1>
                                        <h2 className="mt-4 text-xl text-white md:text-2xl">
                                            A full-service automotive trim shop.
                                        </h2>
                                        {/* <p className="mt-3 text-base text-gray-300 sm:mt-5 sm:text-xl lg:text-lg xl:text-xl">
                                        There is nothing that our team of experts can't do. We guarantee unmatchable craftsmanship and perfection.
                                    </p> */}
                                        <p className="mt-2 text-center lg:text-left text-gray-300 md:mt-4 sm:text-xl lg:text-lg italic">
                                            34 years in business
                                        </p>
                                        {/* <p className="mt-8 text-base font-semibold text-white sm:mt-10">Used by</p> */}
                                        <div className="mt-6 flex">
                                            <div className="mx-auto">
                                                <a id="bbblink" className="ruhzbum" href="http://www.bbb.org/tulsa/business-reviews/auto-upholstery/southeast-auto-trim-in-tulsa-ok-29000913#bbbseal" title="Southeast Auto Trim, Inc., Auto Upholstery, Tulsa, OK">
                                                    <img id="bbblinkimg" src="http://seal-tulsa.bbb.org/logo/ruhzbum/southeast-auto-trim-29000913.png" alt="Southeast Auto Trim, Inc., Auto Upholstery, Tulsa, OK" style={{ width: 250 }} />
                                                </a>
                                            </div>




                                        </div>
                                    </div>
                             
                            </div>


                            <div className="mt-16 sm:mt-24 lg:col-span-6 lg:mt-0 hidden md:block">
                                <div className="bg-white sm:mx-auto sm:w-full sm:max-w-md sm:overflow-hidden sm:rounded-lg">
                                    <div className="px-6 py-8 sm:px-10">
                                        <div>
                                            <p className="text-4xl font-medium text-gray-700 text-center">Contact Us</p>
                                        </div>
                                        <p className="mt-2 text-md text-gray-500  text-center">For a fast quote, click below.</p>


                                        <div className="mt-4">

                                            <div>
                                                <button
                                                    type="submit"
                                                    onClick={getQuote}
                                                    className="flex w-full justify-center rounded-md border border-transparent bg-red-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                                                >
                                                    Get A Quote!
                                                </button>
                                            </div>
                                            {/* </form> */}
                                        </div>

                                        <div className="relative mt-6">
                                            <div aria-hidden="true" className="absolute inset-0 flex items-center">
                                                <div className="w-full border-t border-gray-300" />
                                            </div>
                                            <div className="relative flex justify-center text-sm">
                                                <span className="bg-white px-2 text-gray-500">Or</span>
                                            </div>
                                        </div>

                                        <p className="underline text-2xl text-center mt-6">Call us: <a href="tel:918-664-7328" >(918) 664-7328</a></p>
                                    </div>
                                    {/* <div className="border-t-2 border-gray-200 bg-gray-50 px-6 py-6 sm:px-10">
                    <p className="text-xs leading-5 text-gray-500">
                      By signing up, you agree to our{' '}
                      <a href="#" className="font-medium text-gray-900 hover:underline">
                        Terms
                      </a>
                      ,{' '}
                      <a href="#" className="font-medium text-gray-900 hover:underline">
                        Data Policy
                      </a>{' '}
                      and{' '}
                      <a href="#" className="font-medium text-gray-900 hover:underline">
                        Cookies Policy
                      </a>
                      .
                    </p>
                  </div> */}
                                </div>
                                <br />
                            </div>
                        </div>
                           )}
                    </div>
                </main>
            </div>

        </div>


    )
}


export default Header;
