
import factory from './images/factorySeats.jpg'
import moto from './images/motorcycleSeats.jpg';
import classic from './images/customBrown.jpg'
import boat from './images/boatTrim.jpg';
import './App.css';
import { CheckIcon } from '@heroicons/react/24/outline'
import { Link, useNavigate } from 'react-router-dom'


// const navigation = [
//   { name: 'Product', href: '#' },
//   { name: 'Features', href: '#' },
//   { name: 'Marketplace', href: '#' },
//   { name: 'Company', href: '#' },
// ]

// const people = [
//   {
//     name: 'Leonard Krasner',
//     role: 'Senior Designer',
//     imageUrl:
//       'https://images.unsplash.com/photo-1519345182560-3f2917c472ef?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=8&w=1024&h=1024&q=80',
//     bio: 'Quia illum aut in beatae. Possimus dolores aliquid accusantium aut in ut non assumenda. Enim iusto molestias aut deleniti eos aliquid magnam molestiae. At et non possimus ab. Magni labore molestiae nulla qui.',
//   }
// ]


function Products() {

    var auto = ['Headliners', 'Factory Restoration', 'Door Panels', 'Dash Recover', 'Carpets', 'Sun Visors', 'Seat Fabrication &amp; Repairs', 'Seat Heaters & Massage Mechanisms', 'Seat Height Adjustmnts', 'Power Lumbar', 'Truck Liners', 'Custom Trim Accessories'];

    var exterior = ['Paintless Dent Repair', 'Convertible Tops &amp; Hydraulics', 'Step Rails',  'Side Moldings' , 'Rear Window Replacement', 'Convertible Top Restoration', 'Custom Car Covers', 'Accessory Replacement &amp; Restyle'];   
                                     
    var marine = ['Minor Repairs', 'Complete Seat Refinishing', 'Boat Covers', 'Marine Accessory Installs', 'Marine Dashboards']
    
    const navigate = useNavigate();

    const getQuote = () => {
        navigate("/contact");
    }


    return (
        <>

            <div className="bg-white py-6">
                <div className="mx-auto max-w-7xl px-6 lg:px-8">
                  
                    <div className="mx-auto mt-6 max-w-2xl sm:mt-20 lg:mt-6 lg:max-w-none">
                    <h1 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">Services</h1>
                    <br />
                        <dl className="grid max-w-xl grid-cols-1 gap-x-8 gap-y-16 lg:max-w-none lg:grid-cols-3">

                            <div key={1} className="flex flex-col">
                                <dt className="flex items-center gap-x-3 text-base font-semibold leading-7 text-gray-900">
                                    
                                    
                                </dt>
                                <h2 className="text-xl font-bold tracking-tight text-red-700">Auto Interior</h2>
                                <div className="mt-1"></div>
                             
                                <dd className="mt-4 flex flex-auto flex-col text-base leading-7 text-gray-600">

                                    {auto.map((r) => {
                                        return (
                                            <div className='flex' >
                                                <CheckIcon aria-hidden="true" className=" mt-1 h-6 w-6 text-indigo-600 inline" />
                                                <p className="ml-4 text-lg font-normal leading-8 inline">{r}</p>
                                            </div>
                                        );
                                    })

                                    }

                                </dd>

                             
                        <img alt="" src={factory} className="aspect-[4/5] w-52 flex-none rounded-2xl object-cover float-right" />

                            </div>

                            <div key={2} className="flex flex-col">
                                <dt className="flex items-center gap-x-3 text-base font-semibold leading-7 text-gray-900">
                                    {/* Exterior */}
                                </dt>
                                <h2 className="text-xl font-bold tracking-tight text-red-700">Exterior</h2>
                        <div className="mt-1"></div>
                    
                                <dd className="mt-4 flex flex-auto flex-col text-base leading-7 text-gray-600">

                                {exterior.map((r) => {
                                        return (
                                            <div className='flex' >
                                                <CheckIcon aria-hidden="true" className=" mt-1 h-6 w-6 text-indigo-600 inline" />
                                                <p className="ml-4 text-lg font-normal leading-8 inline">{r}</p>
                                            </div>
                                        );
                                    })

                                    }
                                     <img alt="" src={moto} className="aspect-[4/5] w-52 flex-none rounded-2xl object-cover float-right" />
                                </dd>
                               
                            </div>

                            <div key={3} className="flex flex-col">
                                <dt className="flex items-center gap-x-3 text-base font-semibold leading-7 text-gray-900">
                                    {/* Marine & Motorcycle */}
                                </dt>
                                
                        {/* <h2 className="text-2xl font-semibold tracking-tight text-red-500">Marine</h2> */}
                        <h2 className="text-xl font-bold tracking-tight text-red-700">Marine & Motorcycle</h2>
                        <div className="mt-1"></div>
                                <dd className="mt-4 flex flex-auto flex-col text-base leading-7 text-gray-600">
                                   
                                   
                                {marine.map((r) => {
                                        return (
                                            <div className='flex' >
                                                <CheckIcon aria-hidden="true" className=" mt-1 h-6 w-6 text-indigo-600 inline" />
                                                <p className="ml-4 text-lg font-normal leading-8 inline">{r}</p>
                                            </div>
                                        );
                                    })

                                    }
                               <img alt="" src={boat} className="aspect-[4/5] w-52 flex-none rounded-2xl object-cover float-right" />
                                </dd>
                            </div>

                        </dl>
                    </div>
                </div>
            </div>

            <div className="bg-white px-6  lg:px-8">
      <div className="mx-auto max-w-2xl text-center">
        <p className="text-base font-semibold leading-7 text-indigo-600">Have questions?</p>
        <h2 className="mt-2 text-4xl font-bold tracking-tight text-gray-900 sm:text-6xl">Contact Us</h2>
        <p className="mt-6 text-lg leading-8 text-gray-600">
         Contact us today for questions or a quick quote. 
        </p>
      </div>
    </div>

            <div className="bg-white py-12">

             
                <div className="flex justify-center" >
                    <button
                        type="submit"
                        onClick={getQuote}
                        className="rounded-md border text-xl px-10 border-transparent bg-red-600 px-4 py-2 text-sm font-bold text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                    >
                        Get A Quote
                    </button>

                </div>
            </div>

        </>
    )
}

export default Products;
