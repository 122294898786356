import './App.css';
import { CheckIcon } from '@heroicons/react/24/outline'
import { StarIcon } from '@heroicons/react/20/solid'
import Button from '@mui/material/Button'
import {Link,useNavigate} from 'react-router-dom'


function Home() {

    
    const navigate = useNavigate();

    const viewServices = () => {
        navigate("/services");
    }

    const getQuote = () => {
        navigate("/contact");
    }

    return (

        <main className="pt-4 bg-gray-300">

            <div className="mx-auto max-w-7xl">
                <div className="lg:grid lg:grid-cols-12 lg:gap-8">
                    <div className="px-6 sm:text-center md:mx-auto md:max-w-2xl lg:col-span-7  lg:items-center lg:text-left">
                        <h1 className="mt-6 text-3xl ">Tulsa's upholstery company</h1>
                        {/* <h1>Hello, and welcome!</h1> */}
                        <p className="mt-3 text-base sm:mt-5 sm:text-lg">
                            Our friendly and experienced staff have provided great services at great rates for over 30 years.
                            <br />
                            <br />
                            Whether its a factory restoration or custom upholstery, you can trust the expertise of our skilled team. SEAT has you covered!
                            <br />
                            <br />
                            There is nothing that our team of experts can't do, and our work is guaranteed, so contact us today for a quick and easy quote.
                        </p>
                        <br />
                        <div className="flex justify-center" >
                        <button
                                                        type="submit"
                                                        onClick={getQuote}
                                                        className="rounded-md border text-xl px-10 border-transparent bg-red-600 px-4 py-2 text-sm font-bold text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                                                    >
                                                        Get A Quote
                                                    </button>
                                    
                        </div>
                     
                    </div>

                    <div className="px-6 sm:text-center lg:col-span-5  lg:text-left">
                        <h2 className="text-center md:text-left mt-12 text-3xl font-bold">Services</h2>
                        <br />
                        <div className="px-8">
                            <CheckIcon aria-hidden="true" className="absolute h-8 w-8 text-red-700" />
                            <p className="ml-10 font-bold text-red-700 text-xl leading-8"> Factory Interior Restoration</p>
                        
                        <CheckIcon aria-hidden="true" className="absolute mt-4 h-8 w-8 text-red-700" />
                        <p className="ml-10 mt-4 font-bold text-red-700 text-xl leading-8">Dent Repair</p>

                        <CheckIcon aria-hidden="true" className="absolute mt-4 h-8 w-8 text-red-700" />
                        <p className="ml-10 mt-4 font-bold text-red-700 text-xl leading-8">Luxury Car Trim Work</p>

                        <CheckIcon aria-hidden="true" className="absolute mt-4 h-8 w-8 text-red-700" />
                        <p className="ml-10 mt-4 font-bold text-red-700  text-xl leading-8">Marine Interiors & Covers</p>

                        <CheckIcon aria-hidden="true" className="absolute mt-4 h-8 w-8 text-red-700" />
                        <p className="ml-10 mt-4 font-bold text-red-700 text-xl leading-8">Custom Motorcycle Seats</p>
                        </div>
                        <br />  
                        {/* <Button variant="contained" fullWidth={true}>View All Services</Button> */}

                        <div className="flex mt-4 justify-center md:justify-start" >
                        <button
                                                        type="submit"
                                                        onClick={viewServices}
                                                        className="rounded-md border text-xl px-10 border-transparent bg-gray-800 px-4 py-2 text-sm font-bold text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                                                    >
                                                        View All Services
                                                    </button>
                                                    </div>

                    </div>

                    <br />
                    <br />
                    {/* <p className="text-3xl font-bold">Contact us today!</p> */}
                </div>


            </div>

            <div className="mt-12 mx-auto max-w-7xl">
                <h2 className="text-3xl text-gray-700 font-bold sm:text-4xl text-center pb-12">Satisfied Customers</h2>
                <div className="lg:grid lg:grid-cols-12 lg:gap-4 px-12">


                    <div className="sm:text-center md:mx-auto md:max-w-2xl lg:col-span-5  lg:text-left">
                        <figure
                            key={1}
                            className="rounded-2xl bg-white p-6 shadow-lg ring-1 ring-gray-900/5"
                        >
                            <blockquote className="text-gray-900">
                                <p>{`“The team at SEAT did an awesome job recovering my Jet Ski seat cover. All of the seams were sewed perfectly and the material is very durable. ”`}</p>
                            </blockquote>
                            <figcaption className="mt-6 flex items-center gap-x-4">
                                <div>
                                    <div className="font-semibold">Mike J.</div>
                                </div>
                            </figcaption>
                        </figure>
                        <br />
                        <figure
                            key={2}
                            className="rounded-2xl bg-white p-6 shadow-lg ring-1 ring-gray-900/5"
                        >
                            <blockquote className="text-gray-900">
                                <p>{`“I just got my car back from SEAT and I couldn't be happier. I took my car to 3 different shops for quotes for my convertible top as it was damaged and old. Bobby at SEAT was the only one to really look inside the car and inspect it from all sides. While doing the replacement after removing the old top additional damage was found that could not be seen with the old top in place and SEAT went out of their way to make everything right at an incredible price. Bottom line, SEAT went above and beyond throughout the entire process to make sure that the job was done right. Even with the additional work that as found during the job, it all only took them 2 days and it looks amazing.”`}</p>
                            </blockquote>
                            <figcaption className="mt-6 flex items-center gap-x-4">
                                <div>
                                    <div className="font-semibold">Thank you SEAT!</div>
                                </div>
                            </figcaption>
                        </figure>
                    </div>
                    <div className="mt-6 sm:text-center  md:max-w-2xl lg:col-span-7  lg:items-center lg:text-left">
                        <figure
                            key={2}
                            className="rounded-2xl bg-white p-6 shadow-lg ring-1 ring-gray-900/5"
                        >
                            <blockquote className="text-gray-900">
                                <p>{`“I had a new soft top and window installed on my 1998 BMW 323i. From time I initially talked to Bobby about it, then actually having it done and picking it up was all superlative. The actual work including ordering the top and installation took under a week and actually I was tardy because of my schedule. When picking up the vehicle Bobby made sure I know how to maintain it properly. I think Jonathan was expecting a car based business where repairs are done to be bid on immediately, nope not how it works, car has to be seen to see what actually needs to be done and what grade of material you want to use etc.. I am in the car business myself and totally recommend their work.”`}</p>
                            </blockquote>
                            <figcaption className="mt-6 flex items-center gap-x-4">
                                <div>
                                    <div className="font-semibold">Thank you SEAT!</div>
                                </div>
                            </figcaption>
                        </figure>

                    </div>
                </div>
            </div>
        </main>
    )
}


export default Home