import { useEffect, useState } from 'react';
import './App.css';
import { BuildingOffice2Icon, EnvelopeIcon, PhoneIcon } from '@heroicons/react/24/outline'
import { loadCaptchaEnginge, LoadCanvasTemplate, LoadCanvasTemplateNoReload, validateCaptcha } from 'react-simple-captcha';
import axios from 'axios'
import { CircularProgress, DialogContent } from '@mui/material';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import Alert from '@mui/material/Alert';

function Contact() {

    const [name, setName] = useState();
    const [email, setEmail] = useState();
    const [phone, setPhone] = useState();
    const [message, setMessage] = useState();
    const [captcha, setCaptcha] = useState();
    const [open, setOpen] = useState(false);
    const [alertError, setAlertError] = useState(false);
    const [alertSuccess, setAlertSuccess] = useState(false);
    const [dialogContent, setDialogContent] = useState('');
    const [sending, setSending] = useState(false);

    useEffect(() => {
        loadCaptchaEnginge(6);
    }, [])

    const changeName = (e, v) => {
        setName(e.target.value);
    }
    const changePhone = (e, v) => {
        setPhone(e.target.value);
    }
    const changeEmail = (e, v) => {
        setEmail(e.target.value);
    }
    const changeMessage = (e, v) => {
        setMessage(e.target.value);
    }
    const changeCaptcha = (e, v) => {
        setCaptcha(e.target.value)
    }
    const handleClose = (e, v) => {
        setOpen(false)
    }

    const sendMessage = () => {

        setSending(true);
        setAlertError(false);
        setAlertError(false);

        if ((!name || name == "") || 
                (!email || email == "") || 
                (!message || message == "") ||
                (!captcha || captcha == "")
            ) {
                setDialogContent('The Name, Email, Message, and Captcha Validation fields are reuqired to submit the form. Please provide this info and try again.')
                setSending(false);
                setOpen(true)
                return;
            }

            if (validateCaptcha(captcha) == true) {
                axios.post('https://rws-email-service.azurewebsites.net/api/email/send-seat-email', {
                    //'https://localhost:7142/api/email/send-seat-email', {
                    //'https://rws-email-service.azurewebsites.net/api/email/send-seat-email', {
                    Name: name,
                    Phone: phone,
                    Email: email,
                    Message: message,
                    Key: '2FE8FF6F-6134-4EBD-A616-4F1597ED32CA'
                  })
                  .then(function (response) {
                    setAlertSuccess(true)
                    setName("")
                    setEmail("")
                    setPhone("")
                    setMessage("")
                    setCaptcha("")
                  })
                  .catch(function (error) {
                    setAlertError(true)
                  })
                  .finally(() =>{
                    setSending(false);
                  });
            }
            else {
                setDialogContent('The validation characters do not match. Please try again.')
                setCaptcha('')
                setSending(false);
                setOpen(true)
            }
    };
    return (


        <div className="relative isolate bg-white">
            <div className="mx-auto grid max-w-7xl grid-cols-1 lg:grid-cols-2">
                <div className="relative px-6 pb-6 pt-12 sm:pt-12 lg:static lg:px-8 lg:py-6">
                    <div className="mx-auto max-w-xl lg:mx-0 lg:max-w-lg">
                        <div className="absolute inset-y-0 left-0 -z-10 w-full overflow-hidden bg-gray-100 ring-1 ring-gray-900/10 lg:w-1/2">
                            <svg
                                aria-hidden="true"
                                className="absolute inset-0 h-full w-full stroke-gray-200 [mask-image:radial-gradient(100%_100%_at_top_right,white,transparent)]"
                            >
                                <defs>
                                    <pattern
                                        x="100%"
                                        y={-1}
                                        id="83fd4e5a-9d52-42fc-97b6-718e5d7ee527"
                                        width={200}
                                        height={200}
                                        patternUnits="userSpaceOnUse"
                                    >
                                        <path d="M130 200V.5M.5 .5H200" fill="none" />
                                    </pattern>
                                </defs>
                                <rect fill="white" width="100%" height="100%" strokeWidth={0} />
                                <svg x="100%" y={-1} className="overflow-visible fill-gray-50">
                                    <path d="M-470.5 0h201v201h-201Z" strokeWidth={0} />
                                </svg>
                                <rect fill="url(#83fd4e5a-9d52-42fc-97b6-718e5d7ee527)" width="100%" height="100%" strokeWidth={0} />
                            </svg>
                        </div>
                        <h2 className="text-3xl font-bold tracking-tight text-gray-900">Contact & Quotes</h2>
                        <p className="mt-4 text-lg leading-8 text-gray-600">
                            For quotes or questions please use the contact form, or call us directly during office hours.
                        </p>
                        <dl className="hidden md:block mt-10 space-y-4 text-base leading-7 text-gray-600">
                            <div className="flex gap-x-4">
                                <dt className="flex-none">
                                    <span className="sr-only">Address</span>
                                    <BuildingOffice2Icon aria-hidden="true" className="h-7 w-6 text-gray-400" />
                                </dt>
                                <dd>
                                    7686 E 46th St
                                    <br />
                                    Tulsa, OK 74145
                                </dd>
                            </div>
                            <div className="flex gap-x-4">
                                <dt className="flex-none">
                                    <span className="sr-only">Telephone</span>
                                    <PhoneIcon aria-hidden="true" className="h-7 w-6 text-gray-400" />
                                </dt>
                                <dd>
                                    <a href="tel:+1 (918) 664-7328" className="hover:text-gray-900 underline">
                                        Local: (918) 664-7328
                                    </a>
                                    <br />
                                    <a href="tel:+1 (877) 530-7328" className="hover:text-gray-900 underline">
                                        Toll Free: 1-877-530-7328
                                    </a>


                                </dd>
                            </div>
                            {/* <div className="flex gap-x-4">
                                <dt className="flex-none">
                                    <span className="sr-only">Email</span>
                                    <EnvelopeIcon aria-hidden="true" className="h-7 w-6 text-gray-400" />
                                </dt>
                                <dd>
                                    <a href="mailto:hello@example.com" className="hover:text-gray-900">
                                        hello@example.com
                                    </a>
                                </dd>
                            </div> */}
                        </dl>
                    </div>
                </div>

                <div className="px-6 pb-24 pt-20 sm:pb-32 lg:px-8 lg:py-4">
                    <div className="mx-auto max-w-xl lg:mr-0 lg:max-w-lg">
                        <div className="grid grid-cols-1 gap-x-8 gap-y-6 sm:grid-cols-2">
                            <div className="sm:col-span-2">
                                <label htmlFor="first-name" className="block text-sm font-semibold leading-6 text-gray-900">
                                    Name <span className='text-red-500 font-normal'> * required</span>
                                </label>
                                <div className="mt-2.5">
                                    <input
                                        id="first-name"
                                        name="first-name"
                                        type="text"
                                        value={name}
                                        onChange={changeName}
                                        autoComplete="given-name"
                                        className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                    />
                                </div>
                            </div>
                            <div >
                                <label htmlFor="email" className="block text-sm font-semibold leading-6 text-gray-900">
                                    Email <span className='text-red-500 font-normal'> * required</span>
                                </label>
                                <div className="mt-2.5">
                                    <input
                                        id="email"
                                        name="email"
                                        type="email"
                                        required="true"
                                        value={email}
                                        onChange={changeEmail}
                                        autoComplete="email"
                                        className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                    />
                                </div>
                            </div>
                            <div >
                                <label htmlFor="phone-number" className="block text-sm font-semibold leading-6 text-gray-900">
                                    Phone number
                                </label>
                                <div className="mt-2.5">
                                    <input
                                        id="phone-number"
                                        name="phone-number"
                                        type="tel"
                                        value={phone}
                                        onChange={changePhone}
                                        autoComplete="tel"
                                        className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                    />
                                </div>
                            </div>
                            <div className="sm:col-span-2">
                                <label htmlFor="message" className="block text-sm font-semibold leading-6 text-gray-900">
                                    Message <span className='text-red-500 font-normal'> * required</span>
                                </label>
                                <div className="mt-2.5">
                                    <textarea
                                        id="message"
                                        name="message"
                                        rows={4}
                                        value={message}
                                        onChange={changeMessage}
                                        className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                        defaultValue={''}
                                    />
                                </div>
                            </div>
                            <div >
                                <label htmlFor="phone-number" className="block text-sm font-semibold leading-6 text-gray-900">
                                    Human Validation  <span className='text-red-500 font-normal'> * required</span>
                                </label>
                                <div className="mt-2.5">
                                    <input
                                        id="captcha"
                                        name="captcha"
                                        type="text"
                                        value={captcha}
                                        onChange={changeCaptcha}
                                        className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                    />
                                    <p>Enter the validation characters.</p>
                                </div>
                            </div>
                            <div className="pt-5 pl-5">
                            <LoadCanvasTemplate />
                            </div>
                        </div>
                        <div className="mt-8 flex justify-end">

                            {sending && (
                                <CircularProgress />
                            )}


                            {alertError && (
                                <Alert severity="error">An error occured, please try again. </Alert>
                            )}
                            {alertSuccess && (
                                <Alert severity="success">Your message has been sent!</Alert>
                            )}
                            

                            {!sending && !alertSuccess &&(
                                <button
                                    onClick={sendMessage}
                                    className="rounded-md bg-indigo-600 px-3.5 py-2.5 text-center text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                                >
                                    Send message
                                </button>
                            )}
                        </div>
                    </div>
                </div>
                {/* <iframe data-tally-src="https://tally.so/r/mVY2pN?transparentBackground=1" style={{width:"100%", height:"100%", frameBorder:0}} ></iframe> */}

            </div>

            <Dialog onClose={handleClose} open={open}>
                <DialogTitle>Required Fields</DialogTitle>
                <DialogContent>{dialogContent}</DialogContent>
            </Dialog>

            <dl className="block md:hidden mb-10 space-y-4 text-base leading-7 text-gray-600 px-12">

                <h2 className="text-3xl font-bold tracking-tight text-gray-900">Address & Phone</h2>
                <div className="flex gap-x-4">
                    <dt className="flex-none">
                        <span className="sr-only">Address</span>
                        <BuildingOffice2Icon aria-hidden="true" className="h-7 w-6 text-gray-400" />
                    </dt>
                    <dd>
                        7686 E 46th St
                        <br />
                        Tulsa, OK 74145
                    </dd>
                </div>
                <div className="flex gap-x-4">
                    <dt className="flex-none">
                        <span className="sr-only">Telephone</span>
                        <PhoneIcon aria-hidden="true" className="h-7 w-6 text-gray-400" />
                    </dt>
                    <dd>
                        <a href="tel:+1 (918) 664-7328" className="hover:text-gray-900 underline">
                            Local: (918) 664-7328
                        </a>
                        <br />
                        <a href="tel:+1 (877) 530-7328" className="hover:text-gray-900 underline">
                            Toll Free: 1-877-530-7328
                        </a>


                    </dd>
                </div>
            </dl>
        </div>

    )
}


export default Contact