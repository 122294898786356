
import { Popover, PopoverButton, PopoverPanel } from '@headlessui/react'
import { Bars3Icon, XMarkIcon } from '@heroicons/react/24/outline'
import { ChevronRightIcon } from '@heroicons/react/20/solid'
import leather from './images/Leather-Trim.jpg';
import vinyl from './images/VinylSelection.jpg';
import foams from './images/foams.png';
import './App.css';
import { CheckIcon } from '@heroicons/react/24/outline'

// const navigation = [
//   { name: 'Product', href: '#' },
//   { name: 'Features', href: '#' },
//   { name: 'Marketplace', href: '#' },
//   { name: 'Company', href: '#' },
// ]

// const people = [
//   {
//     name: 'Leonard Krasner',
//     role: 'Senior Designer',
//     imageUrl:
//       'https://images.unsplash.com/photo-1519345182560-3f2917c472ef?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=8&w=1024&h=1024&q=80',
//     bio: 'Quia illum aut in beatae. Possimus dolores aliquid accusantium aut in ut non assumenda. Enim iusto molestias aut deleniti eos aliquid magnam molestiae. At et non possimus ab. Magni labore molestiae nulla qui.',
//   }
// ]

function Products() {
  return (


    

          <div className="bg-white py-12 sm:py-24">
            <div className="mx-auto max-w-7xl px-6 lg:px-8">
              <div className="mx-auto max-w-2xl sm:text-center">
                <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">Products</h2>
                <p className="mt-6 text-lg leading-8 text-gray-600">
                  We source quality materials and equipment to ensure a job well done. 
                </p>
              </div>
              <ul
                role="list"
                className="mx-auto mt-10 grid max-w-2xl grid-cols-1 gap-x-6 gap-y-20 sm:grid-cols-2 lg:max-w-4xl lg:gap-x-8 xl:max-w-none"
              >
                   <li key={1} className="flex flex-col gap-10 pt-12 sm:flex-row">
                        <img alt="" src={leather} className="aspect-[4/5] w-52 flex-none rounded-2xl object-cover" />
                        <div className="max-w-xl flex-auto">
                            <h2 className="text-3xl font-normal tracking-tight text-gray-900">Leather</h2>
                            <p className="mt-6 text-base leading-7 text-gray-600">We carry custom leather upholstery to choose from. Leather is excellent for custom leather motorcycle seats, elegant leather auto interior inlays, custom truck inlays, or any other custom upholstery work that needs to look professional.</p>

                        </div>
                    </li>

                    <li key={1} className="flex flex-col gap-10 pt-12 sm:flex-row">
                        <img alt="" src={vinyl} className="aspect-[4/5] w-52 flex-none rounded-2xl object-cover" />
                        <div className="max-w-xl flex-auto">
                            <h2 className="text-3xl font-normal tracking-tight text-gray-900">Vinyl</h2>
                            <p className="mt-6 text-base leading-7 text-gray-600">We have a large selection of vinyl upholstery to choose from. In our shop vinyl is used mostly for durable upholstery projects such as boat upholstery and golf cart upholstery. Please contact us with questions about specific vinyl colors or patterns.</p>

                        </div>
                    </li>

                    <li key={1} className="flex flex-col gap-10 pt-12 sm:flex-row">
                        <img alt="" src={foams} className="aspect-[4/5] w-52 flex-none rounded-2xl object-cover" />
                        <div className="max-w-xl flex-auto">
                            {/* <h3 className="text-2xl font-normal leading-8 tracking-tight text-gray-900">Foams</h3> */}
                            <h2 className="text-3xl font-normal tracking-tight text-gray-900">Foams</h2>
                            <p className="mt-6 text-base leading-7 text-gray-600">Each type of foam has its own softness and reaction allowing for both hard and soft surfaces.</p>

                        </div>
                    </li>
              </ul>
            </div>
          </div>

  )
}


export default Products;
